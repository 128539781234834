import React from "react"
import Layout from "../../components/global/layout"
import { CardCarousel } from "../../components/block/CardCarousel"
import { urls } from "../../data/urls"

const cards = [
  {
    type: "text",
    to: null,
    text: () => (
      <>
        <p>
          Let's talk about sex, baby. Sex education can focus on the negatives
          such as sexually transmitted infections or the risk of unplanned
          pregnancy – all of which you need to know about. But it misses out on
          a really important topic – pleasure.
        </p>
        <p>
          During arousal (when you're turned on) blood flows to the vaginal
          walls and triggers lubrication which makes the vagina wet. The vulva
          can also swell and the vagina expands.
        </p>
        <p>
          As you reach orgasm, the clitoris pulls back against the pubic bone.
          When an orgasm happens, the tension is released, the genital muscles
          relax and feel-good chemicals are released by your brain. Oh yes!
        </p>
        <p>
          Sex shouldn't be painful for you. But if it is, there are a number of
          possible causes: not enough lubrication, the position, how you're
          feeling or even an infection. We'll cover some of these later on, but
          speak to a doctor or nurse if you're worried.
        </p>
      </>
    ),
    backgroundColor: "rose",
    textColor: "white",
    withSkip: true,
  },
  {
    linkText: "Take the quiz!",
    to: urls.LYV.NEXT,
    type: "video",
    url: "https://player.vimeo.com/video/461741480?dnt=1",
  },
]

export default function Outro() {
  return (
    <Layout>
      <CardCarousel cards={cards} />
    </Layout>
  )
}
